.loading-main{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 110;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button{
    padding: 0;
    border: 0;
    background: transparent;
    color: white;
    font-size: 1rem;
    font-weight: 600;
  }
  img{
    max-width: 300px;
    border-radius: 50%;
    @media (max-width: 768px) {
      max-width: 200px;
    }
  }
}