.nav {
  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
  }

  &-back-link {
    width: 77px;
    height: 75px;
    border-bottom-right-radius: 100%;
    background: #0080ce url(../../images/home.svg) 15px 15px no-repeat;
    background-size: 32px 32px;
    border: 2px solid #000000;
    box-shadow: 3px 3px 0px #000000;
    z-index: 10;

    @media only screen and (max-width: 900px) {
      width: 57px;
      height: 57px;
      background-size: 24px 23px;
      background-position: 11px 12px;
    }
  }

  &-wallet {
    min-width: 198px;
    display: flex;
    background: #0080ce;
    border: 2px solid #000000;
    box-shadow: 3px 3px 0px #000000;
    border-bottom-left-radius: 100px;
    height: 80px;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 43px;

    @media only screen and (max-width: 900px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      height: 59px;
      padding: 10px 20px 10px 35px;
      min-width: 176px;
    }

    &:before {
      content: '';
      background: url(../../images/chainlink.svg) 0 0 no-repeat;
      background-size: contain;
      width: 24px;
      height: 22px;
      margin: 0 7px 0 0;
    }
  }
}
