.signupform {
  max-width: 431px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #000000;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
  }

  label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
  }

  input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 16px;
    box-sizing: border-box;
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    width: 100%;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .emailError {
    position: relative;
    width: 100%;
    top: 5px;
    bottom: 5px;
    color: red;
    font-size: 10px;
    text-align: center;
  }

  .message {
    text-align: center;
    color: var(--error-color);
  }

  .success {
    font-size: 24px;
    font-weight: 900;

    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  .legal {
    color: #000000;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  .btn {
    width: 270px;
    height: 51px;
    align-items: center;
    background: #ffcf00;
    cursor: url(../../images/cursor.svg), auto;

    @media only screen and (max-width: 900px) {
      width: 160px;
      height: 42px;
    }
  }

  a {
    color: #000;
  }
}
