.signup-wrappar {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  display: flex;
  padding: 20px;
  box-sizing: border-box;

  .outer {
    box-sizing: border-box;
    max-width: 718px;
    max-height: 590px;
    background: #ffafff;
    border: 3px solid #000000;
    border-radius: 20px;
    padding: 2px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
  }

  .inner {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffafff;
    border: 3px solid #000000;
    border-radius: 20px;
    display: block;
    padding: 2px;
    box-sizing: border-box;
  }

  .content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #f1e699;
    border: 3px solid #000000;
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .success-checkbox {
    width: 230px;
    margin-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .success-top-left-modal {
    width: 140px;
    height: 140px;
    position: absolute;
    left: -18px;
    top: -35px;
  }

  .success-bottom-right-modal {
    width: 100px;
    height: 100px;
    position: absolute;
    right: -35px;
    bottom: -25px;
  }

  .close-btn-modal {
    width: 55px;
    height: 55px;
    position: absolute;
    right: -18px;
    top: -18px;
    cursor: url(../../images/cursor.svg), auto;
  }

  .modal-decoration {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .modal-content {
    width: 341px;
    height: 312px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    margin: 15px;
  }

  .btn {
    &:before {
      display: none;
    }
  }
}
