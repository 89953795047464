.modal-wappar-packing {
  .outer {
    max-width: 704px;
    min-height: 344px;
    max-height: 100%;
    background: #ffcf00;
    height: auto;

    @media only screen and (max-width: 900px) {
      width: calc(100% - 30px);
      min-height: 0;
    }
  }

  .content {
    background: #ffffff;
    overflow: hidden;
    padding: 37px 37px 37px 68px;

    @media only screen and (max-width: 900px) {
      padding: 17px 15px 37px;
    }
  }

  .close {
    right: -17px;
    top: -16px;
  }
}

.packing-options {
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}

.packing-option {
  &.book,
  &.hammer,
  &.cube,
  &.skateboard,
  &.binoculars,
  &.ape,
  &.banana,
  &.benji,
  &.boot,
  &.car,
  &.cat,
  &.coldstorage,
  &.hopium,
  &.nothing,
  &.sword {
    &.show {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 710px;
      height: 346px;
      padding: 30px 60px;
      box-sizing: border-box;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    margin: 0 0 20px;

    @media only screen and (max-width: 900px) {
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 10px;
    }
  }

  &-question {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 20px;

    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 10px;
    }
  }

  &-list {
    width: 100%;
  }

  &-item {
    background: #ffcf00;
    border-radius: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    padding: 20px;
    margin: 0 0 10px;
    cursor: url(../../images/cursor.svg), auto;

    @media only screen and (max-width: 900px) {
      margin: 0 0 5px;
    }

    &:last-child {
      margin: 0;
    }

    &:hover {
      input {
        &:before {
          display: block;
        }
      }
    }

    label {
      cursor: url(../../images/cursor.svg), auto;
      flex: 1;
    }

    input {
      cursor: url(../../images/cursor.svg), auto;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 22px;
      min-width: 22px;
      background-color: #fff;
      border-radius: 100%;
      border: 2px solid #000000;
      margin: 0 10px 0 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 1px;
        display: none;
        width: 24px;
        height: 24px;
        background-image: url(../../images/check.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        top: -4px;
      }

      &:checked {
        &:before {
          display: block;
        }
      }
    }
  }

  &-image {
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 137px;
    height: 137px;
    display: block;
    top: -55px;
    left: -55px;
    transform: rotate(-31deg);

    @media only screen and (max-width: 900px) {
      margin: 0 auto;
      top: -155px;
      left: 0;
      right: 0;
      transform: none;
    }
  }
}
.book-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/book.svg);
}
.hammer-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/hammer.svg);
}
.cube-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/cube.svg);
}
.skateboard-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/skateboard.svg);
}
.binoculars-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/binoculars.svg);
}
.ape-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/ape-sign.svg);
}
.banana-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/banana.svg);
}
.benji-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/Benji-plushie.svg);
}
.boot-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/boot.svg);
}
.car-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/car.svg);
}
.cat-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/cat.svg);
}
.coldstorage-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/cold-storage.svg);
}
.hopium-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/Hopium-cannister.svg);
}
.nothing-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/nothing-folder.svg);
}
.sword-image {
  @extend .packing-option-image;
  background-image: url(../../images/packing/sword.svg);
}
