.minting {
  background-image: url(../../images/personality_large.svg);
  background-attachment: scroll;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #05263c;
  padding: 86px 30px 50px;
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (max-width: 1920px) {
    background-image: url(../../images/personality_latop_big.svg);
    background-size: contain;
  }

  @media only screen and (max-width: 1440px) {
    background-image: url(../../images/personality_laptop_small.svg);
  }

  @media only screen and (max-width: 1024px) {
    background-image: url(../../images/personality_tablet.svg);
    padding: 66px 30px 50px;
  }

  @media only screen and (max-width: 900px) {
    padding: 84px 15px 50px;
  }

  @media only screen and (max-width: 500px) {
    background-image: url(../../images/personality_mobile.svg);
  }
}

.personality {
  max-width: 1252px;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    margin: 10% auto 0;
  }

  @media only screen and (max-width: 900px) {
    margin: 0 auto;
  }

  &-title-wrapper {
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #fff;
    max-width: 992px;
    margin: 0 auto 28px;

    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 auto 6px;
    }

    p {
      margin: 0;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 64px;
    line-height: 78px;
    text-transform: uppercase;
    margin: -10px 0 8px;

    @media only screen and (max-width: 900px) {
      font-size: 24px;
      line-height: 29px;
      margin: 0 0 8px;
    }
  }

  &-result-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 37px;
    background-image: url(../../images/legos.svg);
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 900px) {
      background-image: none;
      margin: 0 0 18px;
    }
  }

  &-result-info {
    background: url(../../images/window.svg) 0 0 no-repeat;
    background-size: cover;
    display: block;
    width: 295px;
    height: 314px;
    margin: 0 auto;
    padding: 21px 3px 3px 3px;
    overflow: hidden;

    @media only screen and (max-width: 374px) {
      width: 273px;
      height: 292px;
    }
  }

  &-result-image {
    max-width: 100%;
  }

  &-terms {
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    max-width: 992px;
    margin: 0 auto 5px;

    @media only screen and (max-width: 900px) {
      line-height: 17px;
      margin: 0 auto 18px;
    }
  }

  &-terms-btn {
    background: none;
    border: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-decoration: underline;
    padding: 0;
    margin: 0;
  }

  &-help {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 992px;
    margin: 0 auto;

    &:before {
      content: '';
      background: url(../../images/question.svg) 0 0 no-repeat;
      width: 20px;
      height: 20px;
      background-size: contain;
      margin: 0 5px 0 0;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  &-errors {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .errorWrap {
      margin: 0 auto 30px;
    }
  }

  &-warning {
    color: #f8d748;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    width: 100%;
    max-width: 930px;
    margin: 0 auto;
    text-align: center;

    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 20px;
      p {
        margin-bottom: 10px;
      }
    }
  }
}

.minting-wrapper {
  width: 100%;
  max-width: 992px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto 20px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
    margin: 0 auto 2px;
  }
}

.your-quota {
  &-wrapper {
    box-sizing: border-box;
    width: 25%;
    min-height: 100%;
    background: #0080ce;
    border: 2px solid #000000;
    border-radius: 20px;
    margin: 0 5px;
    overflow: hidden;
    padding: 0 0 10px;

    @media only screen and (max-width: 900px) {
      width: 100%;
      margin: 0 0 11px;
      padding: 0;
    }
  }

  &-title {
    background: #004975;
    border-bottom: 2px solid #000;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    padding: 18px 20px;

    @media only screen and (max-width: 900px) {
      text-align: left;
    }
  }

  &-mint {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 25px 11px 17px;
    border-bottom: 1px solid #0066a4;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.8);

    &:last-child {
      border-bottom: 0;
    }

    .lable {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        background: #f8d748;
        border: 2px solid #000000;
        width: 11px;
        height: 11px;
        border-radius: 20px;
        box-sizing: border-box;
        margin: 0 6px 0 0;
      }
    }

    .number {
      line-height: 24px;
      color: #fff;
      position: relative;
      top: -3px;

      &-current {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .unit {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      padding: 0 0 0 16px;
      width: 100%;
      @media only screen and (max-width: 900px) {
        margin: -4px 0 0;
      }
    }
  }
}

.minting-total {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    min-height: 100%;
    box-sizing: border-box;
    background: #d5bcff;
    border: 2px solid #000000;
    border-radius: 20px;
    margin: 0 5px;
    padding: 20px 10px;
    font-weight: 700;

    @media only screen and (max-width: 900px) {
      width: 100%;
      margin: 8px 0 12px;
      padding: 20px 10px 23px;
    }
  }

  &-title {
    font-size: 18px;
    line-height: 24px;
  }

  &-price {
    font-size: 32px;
    line-height: 40px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
    width: 100%;

    span:not(.unit) {
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
    }

    .unit {
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      padding: 8px 0 0 7px;
    }
  }
}

.quantity {
  display: flex;
  flex-wrap: nowrap;
  height: 50%;
  align-items: center;
  justify-content: center;
  padding: 10px 8px 10px 25px;
  border-bottom: 1px solid #000;

  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
    padding: 17px 15px 21px 15px;
    justify-content: start;
  }

  &-title {
    @media only screen and (max-width: 900px) {
      width: 100%;
      margin: 0 0 8px;
    }
  }

  &-wrapper {
    width: 50%;
    min-height: 100%;
    background: #d5bcff;
    border: 2px solid #000;
    border-radius: 20px;
    margin: 0 5px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    @media only screen and (max-width: 900px) {
      margin: 0;
      width: 100%;
    }
  }

  &-input {
    background: #fff;
    border: 2px solid #000;
    border-radius: 100px;
    padding: 6px 16px 6px 10px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0 14px 0 22px;

    @media only screen and (max-width: 900px) {
      margin: 0 7px 0 -6px;
      max-width: 232px;
    }

    .qty {
      flex: 1;
      text-align: center;
      padding: 0 7px;
    }
  }

  &-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
    background: none;
    padding: 0;

    span {
      background-position: center center;
      background-size: 18px;
      background-repeat: no-repeat;
      background-color: #ffcf00;
      box-shadow: 3px 3px 0px #000000;
      border: 2px solid #000;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      display: block;
      transition: bottom 3s ease-in-out, right 3s ease-in-out;

      &.minus {
        background-image: url(../../images/minus.svg);
      }

      &.plus {
        background-image: url(../../images/plus.svg);
      }

      &:hover {
        bottom: -1px;
        right: -2px;
        position: relative;
      }
    }

    &:disabled {
      span {
        background-color: #a6a6a6;
        &:hover {
          position: static;
        }
      }
    }
  }

  .btn-max {
    min-width: 82px;
    height: 52px;
    padding: 10px 20px;

    &::before {
      display: none;
    }
  }

  &-pay {
    &-with {
      display: flex;
      flex-wrap: nowrap;
      padding: 10px 25px;
      align-items: center;
      height: 50%;
      @media only screen and (max-width: 900px) {
        flex-wrap: wrap;
        padding: 21px 15px 16px;
      }
    }

    &-title {
      @media only screen and (max-width: 900px) {
        width: 100%;
        margin: 0 0 9px;
      }
    }
  }

  &-dropdown {
    display: flex;
    min-width: 256px;
    flex-wrap: wrap;
    margin-left: 22px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 900px) {
      margin-left: -6px;
      min-width: 0;
      width: 100%;
      max-width: 323px;
    }

    &-text {
      text-transform: uppercase;
      background: #ffffff;
      border: 2px solid #000000;
      border-radius: 100px;
      display: flex;
      width: 100%;
      min-height: 52px;
      align-items: center;
      cursor: url(../../images/cursor.svg), auto;
      padding: 5px 16px;
      position: relative;
      z-index: 1;
      @extend .blockchain-icon;

      @media only screen and (max-width: 900px) {
        padding: 5px 14px 5px 10px;

        &:after {
          content: '';
          position: fixed;
          width: 100%;
          height: 100%;
          z-index: 999;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.58);
          display: none;
        }
      }

      &:hover {
        .quantity-dropdown-arrow {
          box-shadow: none;
        }
      }

      .open & {
        &:after {
          display: block;
        }
        &:hover .quantity-dropdown-arrow,
        .quantity-dropdown-arrow {
          box-shadow: 3px 3px 0px #000;
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-arrow {
      border: 2px solid #000;
      background: #ffcf00;
      box-shadow: 3px 3px 0px #000;
      width: 36px;
      height: 36px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;

      &:after {
        content: '';
        width: 17px;
        height: 11px;
        display: block;
        background: url(../../images/dropdown-arrow.svg) center center no-repeat;
        transition: transform 0.4s ease-in-out;
      }
    }

    &-content {
      background-color: #fff;
      width: 100%;
      border: 2px solid #000000;
      border-top: 0;
      padding: 40px 16px 10px;
      border-radius: 0 0 30px 30px;
      position: absolute;
      top: 25px;
      left: 0;
      display: none;

      @media only screen and (max-width: 900px) {
        position: fixed;
        bottom: -2px;
        top: auto;
        border-radius: 30px 30px 0 0;
        z-index: 1000;
        padding: 35px 16px 25px 24px;
      }

      .open & {
        display: block;
      }
    }

    &-scroll {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 190px;

      &::-webkit-scrollbar {
        background: none;
        height: 12px;
        width: 8px;
      }

      &::-webkit-scrollbar-corner {
        background: #bebebe;
      }

      &::-webkit-scrollbar-thumb {
        background: #bebebe;
        border: 2px solid #000;
        border-radius: 100px;
      }
    }

    &-item {
      display: flex;
      flex-wrap: nowrap;
      margin: 0 0 15px;
      @extend .blockchain-icon;
      &:last-child {
        margin: 0;
      }
      @media only screen and (max-width: 900px) {
        margin: 0 0 25px;
      }
      &:hover {
        opacity: 0.7;
      }
    }

    &-close {
      display: none;
      display: block;
      padding: 0;
      margin: 0;
      background: none;
      border: 0;
      width: 55px;
      height: 55px;
      position: absolute;
      right: 8px;
      top: -23px;

      img {
        width: 100%;
      }

      @media only screen and (max-width: 900px) {
        display: block;
      }
    }
  }
}

.btn.mint {
  margin: 0 auto 20px;
  width: 380px;
  height: 52px;

  @media only screen and (max-width: 900px) {
    width: 100%;
    max-width: 350px;
    margin: 0 auto 22px;
  }

  &:disabled {
    background: #9d9d9d;
  }

  &:before {
    display: none;
  }
}

.blockchain-icon {
  &:before {
    content: '';
    background: url(../../images/blockchain/eth.svg) 0 0 no-repeat;
    width: 26px;
    height: 26px;
    display: flex;
    background-size: contain;
  }

  &.ape {
    &:before {
      background-image: url(../../images/blockchain/ape.svg);
    }
  }

  &.sand {
    &:before {
      background-image: url(../../images/blockchain/sand.svg);
    }
  }

  &.revv {
    &:before {
      background-image: url(../../images/blockchain/revv.svg);
    }
  }

  &.astrafer {
    &:before {
      background-image: url(../../images/blockchain/astrafer.svg);
    }
  }

  &.bondly {
    &:before {
      background-image: url(../../images/blockchain/bondly.svg);
    }
  }

  &.dose {
    &:before {
      background-image: url(../../images/blockchain/dose.svg);
    }
  }

  &.gmee {
    &:before {
      background-image: url(../../images/blockchain/gmee.svg);
    }
  }

  &.primate {
    &:before {
      background-image: url(../../images/blockchain/primate.svg);
    }
  }

  &.pros {
    &:before {
      background-image: url(../../images/blockchain/pros.svg);
    }
  }

  &.quidd {
    &:before {
      background-image: url(../../images/blockchain/quidd.svg);
    }
  }

  &.tower {
    &:before {
      background-image: url(../../images/blockchain/tower.svg);
    }
  }

  &.usdc {
    &:before {
      background-image: url(../../images/blockchain/usdc.svg);
    }
  }

  span:not(.quantity-dropdown-arrow) {
    flex: 1;
    padding: 0 10px;
  }
}
