.utilities-section {
  display: flex;
  min-height: 100vh;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100vw;
  padding: 120px 0 0;
  box-sizing: border-box;

  @media only screen and (max-width: 900px) {
    padding: 150px 20px 0;
    min-height: 0;
    height: auto;
    justify-content: flex-start;
  }

  .utilities {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 913px;
    width: 100%;
    margin: 0 auto 50px;

    @media only screen and (max-width: 900px) {
      padding: 0;
    }

    &__block-info {
      background-image: url(../../images/utilitiesinfoblob.svg);
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #F5AA3F;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      margin: 20px 0;
      box-sizing: border-box;
      height: 78px;
      width: 747px;

      @media only screen and (max-width: 900px) {
        background-image: url(../../images/utilitiesinfoblob-mobile.svg);
        height: 85px;
        width: 375px;
        text-align: center;
      }
    }

    .logo-block {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .utilities-title {
        max-width: 300px;
        width: 100%;
      }
    }

    .utilities-block {
      width: 100%;
    }

    .utilities-about-info {
      display: block;
      background-color: #FFF5D3;
      border-top-left-radius: 60px;
      border-bottom-right-radius: 30px;
      margin-bottom:30px;
      border: 2px solid #000000;

      @media only screen and (max-width: 900px) { 
        max-width: 510px;
        width: 100%;
        margin: 0 auto 30px;
      }

      &__header {
        min-height:170px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        border-top-left-radius: 60px;
        border-bottom-right-radius: 30px;

        @media only screen and (max-width: 900px) {
          flex-direction: column;
          height: auto;
        }
      }

      .accordion-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        bottom: 0;
        z-index: 1;

        .accordion-button {
          background: #FFF5D3;
          border-width: 3px 3px 0px 3px;
          border-style: solid;
          border-color: #000000;
          border-radius: 24px 24px 0px 0px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #000000;
          width: 210px;
          cursor: url(../../images/cursor.svg), auto;

        span.arrow {
          transform: rotate(180deg);
        }
      }

      .accordion-button.collapsed {
        span.arrow {
          transform: rotate(0deg);
        }
      }
    }

      .mini-stars {
        background-image: url(../../images/small-stars.svg);
        background-position: center right;
        background-repeat: no-repeat;
        height: 180px;
        width: 517px;
        position: absolute;
        right: -100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 19px 119px 19px 19px;
        box-sizing: border-box;

        @media only screen and (max-width: 900px) {
          background-image: none;
          padding: 100px 19px 30px;
          right: 0;
          left: 0;
          display: flex;
          max-width: 270px;
          margin: 0 auto;
          width: 100%;
          min-height: 50px;
          height: auto;
          position: relative;
          justify-content: space-evenly;
        }

        .logos {
          height: 66px;
          width: 66px;
          display: block;
          border-radius: 100px;
          border: 1px solid #000000;
          margin: 0 5px;
          background-color: #4D4D4D;

          @media only screen and (max-width: 900px) {
            height: 48px;
            width: 48px;
            margin: 0;
          }

          &.last {
            margin-right: 45px;

            @media only screen and (max-width: 900px) {
              margin-right: 0;
            }
          }
        }
      }

      .play-bg {
          background-image: url(../../images/play-bg.svg);
          background-position: top center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 180px;
          max-width: 527px;
          width: 100%;
          position: absolute;
          left: 0;
          top: -10px;
          background-color: #FCCA7F;
          z-index: 1;

          @media only screen and (max-width: 900px) {
            height: 100px;
            background-position: center center;
          }
      }

      .play {
        background-image: url(../../images/play-border.svg);
        background-position: top center;
        background-repeat: no-repeat;
        height: 180px;
        width: 527px;
        position: absolute;
        left: 0;
        top: -10px;
        z-index: 1;

        @media only screen and (max-width: 900px) {
          background-image: url(../../images/play-border-mobile.svg);
          height: 100px;
          background-position: bottom center;
          background-position-y: 75px;
          top: -9px;
        }
      }

      .play-title {
        position: absolute;
        top: 10px;
        width: 200px;

        @media only screen and (max-width: 900px) {
          width: 138px;
        }
      }

      .learn-bg {
        background-image: url(../../images/learn-bg.svg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 180px;
        max-width: 527px;
        width: 100%;
        position: absolute;
        left: 0;
        top: -10px;
        background-color: #EAC1FF;
        z-index: 1;

        @media only screen and (max-width: 900px) {
          height: 100px;
          background-position: center center;
        }
    }

      .learn {
        background-image: url(../../images/learn-border.svg);
        background-position: top center;
        background-repeat: no-repeat;
        height: 180px;
        width: 527px;
        position: absolute;
        left: 0;
        top: -10px;
        z-index: 1;

        @media only screen and (max-width: 900px) {
          background-image: url(../../images/learn-border-mobile.svg);
          height: 100px;
          background-position: bottom center;
          background-position-y: 75px;
          top: -9px;
        }
      }

      .learn-title {
        position: absolute;
        top: 10px;
        width: 230px;

        @media only screen and (max-width: 900px) {
          width: 138px;
        }
      }

      .build-bg {
        background-image: url(../../images/build-bg.svg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 180px;
        max-width: 527px;
        width: 100%;
        position: absolute;
        left: 0;
        top: -10px;
        background-color: #A0E8FF;
        z-index: 1;

        @media only screen and (max-width: 900px) {
          height: 100px;
          background-position: center center;
        }
    }

      .build {
        background-image: url(../../images/build-border.svg);
        background-position: top center;
        background-repeat: no-repeat;
        height: 180px;
        width: 527px;
        position: absolute;
        left: 0;
        top: -10px;
        z-index: 1;

        @media only screen and (max-width: 900px) {
          background-image: url(../../images/build-border-mobile.svg);
          height: 100px;
          background-position: bottom center;
          background-position-y: 75px;
          top: -9px;
        }
      }

      .build-title {
        position: absolute;
        top: 10px;
        width: 230px;

        @media only screen and (max-width: 900px) {
          width: 138px;
        }
      }

      .dogood-bg {
        background-image: url(../../images/dogood-bg.svg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 180px;
        max-width: 527px;
        width: 100%;
        position: absolute;
        left: 0;
        top: -10px;
        background-color: #A8EE9C;
        z-index: 1;

        @media only screen and (max-width: 900px) {
          height: 100px;
          background-position: center center;
        }
    }

      .dogood {
        background-image: url(../../images/dogood-border.svg);
        background-position: top center;
        background-repeat: no-repeat;
        height: 180px;
        width: 527px;
        position: absolute;
        left: 0;
        top: -10px;
        z-index: 1;

        @media only screen and (max-width: 900px) {
          background-image: url(../../images/dogood-border-mobile.svg);
          height: 100px;
          background-position: bottom center;
          background-position-y: 75px;
          top: -9px;
        }
      }

      .dogood-title {
        position: absolute;
        top: 34px;
        width: 290px;

        @media only screen and (max-width: 900px) {
          top: 24px;
          width: 163px;
        }
      }

      .accordion-collapse.collapse {
        display: none;

        &.show {
          border-top:2px solid #000000;
          display: flex;
          height: auto;
          padding: 30px;
          box-sizing: border-box;
        }
      }

      .accordion-body {
        width: 100%;
      }

      .infocard {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        box-sizing: border-box;
        gap: 20px;
        width:100%;
        flex: 1;
        min-height: 110px;
        height: auto;
        background: #FFFFFF;
        border: 2px solid #000000;
        border-radius: 60px;
        margin-bottom: 20px;

        @media only screen and (max-width: 900px) { 
          flex-direction: column;
        }

        &__logo {
          width: 100px;
          height: 100px;
          background-color: #4D4D4D;
          border-radius: 100px;
          border: 2px solid #000000;

          @media only screen and (max-width: 900px) { 
            width: 60px;
            height: 60px;
          }
        }

        &__content {
          width: calc(100% - 130px);
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #464646;

          @media only screen and (max-width: 900px) { 
            width: 100%;
          }

          h3 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #000000;
          }
        }
      }
    }

    span.arrow {
      background: url(../../images/arrowfilled.svg);
      background-repeat: no-repeat;
      width: 18px;
      height: 18px;
      display: block;
      position: absolute;
      right: 37px;
      top: 4px;
    }
  }
}
