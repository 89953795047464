.terms-wappar {
  .outer {
    max-width: 812px;
    max-height: 653px;

    @media only screen and (max-width: 900px) {
      max-height: 565px;
    }
  }

  .content {
    background: #ffffff;
    padding: 30px 7px 45px;

    a {
      color: #000;
    }

    @media only screen and (max-width: 900px) {
      padding: 30px 7px 30px;
    }
  }

  .modal-scroll {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: scroll;
    padding: 5px 26px 30px;

    @media only screen and (max-width: 900px) {
      height: calc(100% - 30px);
      padding: 5px 10px 30px 26px;
    }

    &::-webkit-scrollbar {
      background: none;
      height: 12px;
      width: 9px;
    }

    &::-webkit-scrollbar-corner {
      background: #bebebe;
    }

    &::-webkit-scrollbar-thumb {
      background: #bebebe;
      border: 2px solid #000;
      border-radius: 100px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000;
    margin: 40px 0;

    &.title {
      margin: 0;
      position: relative;
      top: -16px;

      @media only screen and (max-width: 900px) {
        margin: 0 0 10px;
        top: -8px;
      }
    }
  }

  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 10px;
  }

  .letters {
    list-style-type: lower-alpha;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0 20px 40px;

    @media only screen and (max-width: 900px) {
      margin: 20px 0 20px 30px;
    }

    li {
      margin: 10px 0;
    }
  }

  .no-style {
    list-style-type: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0 20px 40px;

    @media only screen and (max-width: 900px) {
      margin: 20px 0 20px 30px;
    }

    li {
      margin: 10px 0;
    }
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 10px;
  }
}
