.realm-modal-wappar {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 2;

	@media only screen and (max-width: 1100px) {
		flex-direction: column;
	}

	.learn-realm,
	.invest-realm,
	.play-realm,
	.dogood-realm {
		display: flex;
		max-width: 500px;
		width: 100%;
	}

  .text {
    position: absolute;
    z-index: 1;
  }
  .realm-modal {
    background-image: url(../../images/realm-modal-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 540px;
    height: 207px;
    display: block;
    position: relative;

    @media only screen and (max-width: 600px) {

      $imageURL: '../../images/modal-packing.png';
      background-image: url($imageURL);
      width:calc( 100% - 60px);
      height: 0;
      background-size: contain;
      padding-top: 91%  /* (321 / 351 * 100) */
    }

  }

  .close-btn-modal {
    width: 55px;
    height: 55px;
    position: absolute;
    right: -18px;
    cursor: url(../../images/cursor.svg), auto;

    @media only screen and (max-width: 600px) {
      top: 0;
    }
  }

  .realm-content {
    width: 535px;
    height: 207px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 28px;
    box-sizing: border-box;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    position: relative;


    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; 
    }

    &::-webkit-scrollbar-thumb {
      background: #BEBEBE; 
      border: 2px solid #000;
      border-radius:100px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #00D1FF;
      cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
      width: 93%;
      overflow-y: scroll;
      padding: 0;
      margin: 50px 0px;
      padding: 10px 0px 35px 30px;
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% - 150px);
    }
  }

	h2 {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-transform: uppercase;
		color: #000000;
		margin: 15px;
	}
}
