.what-is-mocaverse-section {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 20px;
  box-sizing: border-box;

  @media only screen and (max-width: 900px) {
    padding: 150px 0 0;
    box-sizing: border-box;
    min-height: 0;
    height: auto;
  }

  &.bg-clouds4 {
    background-image: url(../../images/bgclouds4.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-width: 900px) {
      background-image: none;
    }
  }

  .wim-row {
    position: relative;
    display: block;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 686px;

    @media only screen and (max-width: 1440px) {
      height: 530px;
      max-width: 1000px;
    }

    @media only screen and (max-width: 900px) {
      min-height: 850px;
    }
  }

  .wim-1 {
    background-image: url(../../images/wim-1.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 630px;
    height: 392px;
    top: -18px;
    left: 0;

    @media only screen and (max-width: 1440px) {
      width: 490px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .content {
      padding-top: 146px;
      padding-left: 143px;
      padding-right: 81px;

      @media only screen and (max-width: 1440px) {
        padding-top: 108px;
        padding-left: 120px;
        padding-right: 59px;
      }
    }
  }

  .wim-2 {
    background-image: url(../../images/wim-2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 542px;
    height: 325px;
    right: 0;
    top: 17%;

    @media only screen and (max-width: 1440px) {
      width: 460px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .content {
      padding-top: 145px;
      padding-left: 61px;
      padding-right: 135px;

      @media only screen and (max-width: 1440px) {
        padding-top: 128px;
        padding-left: 53px;
        padding-right: 105px;
      }
    }
  }

  .wim-3 {
    background-image: url(../../images/wim-3.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 593px;
    height: 287px;
    bottom: -15px;
    left: 5%;

    @media only screen and (max-width: 1440px) {
      width: 504px;
      bottom: -59px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .content {
      padding-top: 95px;
      padding-left: 171px;
      padding-right: 59px;

      @media only screen and (max-width: 1440px) {
        padding-top: 73px;
        padding-left: 153px;
        padding-right: 57px;
      }
    }
  }

  .wim-mobile {
    display: none;
    background-image: url(../../images/what-is-mocaverse-big-mobile.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: absolute;
    width: 348px;
    height: 786px;
    left: 0;
    right: 0;
    margin: 50px auto 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    @media only screen and (max-width: 900px) {
      display: block;
      width: 100%;
      padding: 0px 35px;
      box-sizing: border-box;
    }

    .content {
      padding-top: 247px;
      width: 270px;
      margin: 0 auto;
      overflow-wrap: break-word;
      text-align: center;
    }
  }

  .content {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;

    @media only screen and (max-width: 1440px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .btn.mint {
    margin-top: 20px;
    max-width: 150px;
  }
}
