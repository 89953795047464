.reminding {
  &-wrappar {
    .outer {
      width: 811px;
      max-height: 610px;

      @media only screen and (max-width: 900px) {
        max-width: 100%;
        width: 100%;
        max-height: 551px;
      }

      @media only screen and (max-width: 374px) {
        max-height: 605px;
      }
    }

    .content {
      padding: 30px 30px 45px;

      @media only screen and (max-width: 900px) {
        padding: 20px 5px;
      }
    }

    h2.title {
      @media only screen and (max-width: 900px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  &-title {
    margin: 53px 0 16px;

    @media only screen and (max-width: 900px) {
      margin: 32px 0 15px;
    }
  }

  &-des {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    max-width: 615px;
    margin: 0 auto 28px;
    text-align: center;

    @media only screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 20px;
      margin: 0 auto 30px;
    }
  }

  &-info {
    background: #f98af9;
    border: 2px solid #000000;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: hidden;
    padding: 20px 20px 15px;
    width: 100%;
    max-width: 497px;
    margin: 0 auto 42px;
    min-height: 208px;

    @media only screen and (max-width: 900px) {
      max-width: 298px;
      min-height: 194px;
      margin: 0 auto 48px;
    }

    &-inner {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 320px;
      margin: 0 auto 20px;
    }

    &-image {
      width: 124px;
      border: 2px solid #000000;
      line-height: 0;
      font-size: 0;
      height: 100%;

      @media only screen and (max-width: 900px) {
        width: 108px;
      }
    }

    &-lable {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);
      margin: 0 0 10px;
      display: block;

      @media only screen and (max-width: 900px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-text {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      padding: 10px 0 0 34px;

      @media only screen and (max-width: 900px) {
        font-size: 20px;
        line-height: 24px;
        padding: 10px 0 0 10px;
      }

      p {
        margin: 0 0 10px;
        white-space: nowrap;
      }
    }

    &-num {
      margin: 0 0 0 5px;

      @media only screen and (max-width: 900px) {
        margin: 0 0 0 3px;
      }
    }

    &-actions {
      text-align: center;
    }

    &-data {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      min-height: 77px;
      @media only screen and (max-width: 900px) {
        min-height: 66px;
      }
    }
  }

  &-actions {
    display: flex;
    justify-content: center;
  }
}

.btn-get-them {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
}

.btn.btn-proceed {
  padding: 10px 25px;
  @media only screen and (max-width: 900px) {
    padding: 10px 35px;
    height: 44px;
  }
}
