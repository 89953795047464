.completion-bg-banner-top {
  background-image: url(../../images/minted-background.svg);
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 1439px;
  width: 100%;
  height: 139px;
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  @media only screen and (max-width: 500px) {
    background-image: none;
  }
}

.completion-bg-stars {
  background-image: url(../../images/completion-bg-stars.svg);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.completion-bg {
  background-image: url(../../images/completion-bg.svg);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -7;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.completion {
  width: 100vw;
  height: auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .moca-icon-arrow {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 5px;
  }
}

.realm {
  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    max-width: 1400px;
    width: 100%;
    margin: 56px auto 50px;

    @media only screen and (max-width: 680px) {
      order: 2;
    }
  }

  &__learn,
  &__build,
  &__play,
  &__dogood {
    width: 25%;

    @media only screen and (max-width: 1400px) {
      width: 50%;
    }

    @media only screen and (max-width: 680px) {
      width: 100%;
      max-width: 365px;
      height: auto;
    }

    .text {
      width: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      &.learn {
        top: -25px;
      }

      &.build {
        top: -48px;
        left: -27px;
      }

      &.play {
        top: -25px;
      }

      &.dogood {
        top: -10px;
        width: 200px;
      }
    }

    .realm {
      width: 100%;
    }
  }

  &__content {
    position: relative;
  }
}

.send-moca {
  height: 56px;
  width: 100%;
  position: absolute;
  top: 260px;
  display: flex;
  justify-content: center;

  .mocas {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    display: flex;
    height: 56px;
    justify-content: flex-start;
    align-items: center;
    margin-right: 5px;
    position: relative;

    .count {
      position: absolute;
      top: 0px;
      right: 0px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      background: #ffffff;
      border: 1px solid #000000;
      border-radius: 30px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      padding: 1px 3px 1px 3px;
      line-height: 15px;
      color: #0a0a0a;
      box-shadow: none;
      box-sizing: border-box;
    }
  }

  .btn {
    background-color: #fff;
    width: 135px;
    padding: 0;
    margin-top: 10px;

    &:hover {
      background-color: #fff;
      cursor: url(../../images/cursor.svg), auto;
    }

    &:before {
      display: none;
    }
  }
}

.completion-screen {
  background-image: url(../../images/completion-screen.svg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #75c6f7;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.completion-mint-confirmed {
  background-image: url(../../images/minted_popup.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 898px;
  height: 433px;
  display: block;
  position: relative;
  z-index: 20;
  animation: gototop 1.25s;

  @media only screen and (max-width: 680px) {
    background-image: url(../../images/minted_popup_mobile.svg);
    width: 544px;
    height: 595px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-weight: 700;
    height: 433px;
    width: 270px;
    flex-direction: column;
    position: absolute;
    left: 50%;

    @media only screen and (max-width: 680px) {
      position: relative;
      align-items: center;
      justify-content: flex-end;
      margin: 0 auto;
      left: 0;
      right: 0;
      height: 595px;
      padding: 20px 10px 80px;
      box-sizing: border-box;
    }
  }

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    left: -4px;
    position: relative;

    @media only screen and (max-width: 680px) {
      font-size: 48px;
    }
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    display: block;
    margin: 15px 0;

    @media only screen and (max-width: 680px) {
      font-size: 20px;
    }

    &.sub {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #8f691f;
      display: block;
      text-align: left;

      @media only screen and (max-width: 680px) {
        text-align: center;
      }
    }
  }

  .completion-image {
    width: 244px;
    display: block;
    position: absolute;
    right: 53%;
    top: 87px;
    @media only screen and (max-width: 680px) {
      width: 199px;
      display: block;
      position: absolute;
      right: 173px;
      top: 84px;
    }
    img {
      width: 100%;
    }
  }

  .contract {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .your-wallet-address {
      box-sizing: border-box;
      overflow: hidden;
      width: 189px;
      height: 32px;
      padding: 4px 35px 4px 14px;
      box-sizing: border-box;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #000;
      position: relative;
      display: flex;
      background: #fff;
      border: 2px solid #000;
      border-radius: 100px;
      box-shadow: 3px 3px 0 #000;
      text-decoration: none;

      span:not(.moca-icon-arrow) {
        width: 164px;
        overflow: hidden;
      }

      .moca-icon-arrow {
        width: 20px;
        height: 20px;
        background: url(../../images/blockchain/eth-link.svg) center center
          no-repeat;
        background-size: contain;
      }
    }
  }

  @keyframes gototop {
    from {
      margin-top: calc(50vh - 216px);
    }
    to {
      margin-top: 0vh;
    }
  }
}

.personality-results {
  background-image: url(../../images/personality-results-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 672px;
  height: 466px;
  margin: 0 auto 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;

  @media only screen and (max-width: 680px) {
    background-image: url(../../images/personality-results-bg-mobile.svg);
    width: 356px;
    height: 608px;
    margin: 0 0 50px;
  }
}

.header {
  position: absolute;
  top: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
}

.personality-traits-wrapper {
  display: flex;
  justify-content: space-between;
  height: 75px;
  width: 277px;
  position: absolute;
  top: 107px;
}

.personality-trait {
  display: block;
  height: 76px;
  width: 69px;
  margin: 0px 4px;
  background-repeat: no-repeat;
  background-size: 55px;
  background-position: center center;

  &--book {
    background-image: url(../../images/packing/book.svg);
  }
  &--hammer {
    background-image: url(../../images/packing/hammer.svg);
  }
  &--cube {
    background-image: url(../../images/packing/cube.svg);
  }
  &--skateboard {
    background-image: url(../../images/packing/skateboard.svg);
  }
  &--binoculars {
    background-image: url(../../images/packing/binoculars.svg);
  }
  &--ape {
    background-image: url(../../images/packing/ape-sign.svg);
  }
  &--banana {
    background-image: url(../../images/packing/banana.svg);
  }
  &--benji {
    background-image: url(../../images/packing/Benji-plushie.svg);
  }
  &--boot {
    background-image: url(../../images/packing/boot.svg);
  }
  &--car {
    background-image: url(../../images/packing/car.svg);
  }
  &--cat {
    background-image: url(../../images/packing/cat.svg);
  }
  &--coldstorage {
    background-image: url(../../images/packing/cold-storage.svg);
  }
  &--hopium {
    background-image: url(../../images/packing/Hopium-cannister.svg);
  }
  &--nothing {
    background-image: url(../../images/packing/nothing-folder.svg);
  }
  &--sword {
    background-image: url(../../images/packing/sword.svg);
  }
}

.personality-desc {
  padding: 190px 40px 0 40px;
  color: #ffffff;
  text-align: center;
  font-family: 'Montserrat';
  height: 388px;
  overflow-y: auto;

  @media only screen and (max-width: 680px) {
    padding: 120px 40px 0;
  }

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    margin-bottom: 5px;

    text-align: center;
    text-transform: capitalize;

    @media only screen and (max-width: 680px) {
      margin-bottom: 18px;
    }
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    @media only screen and (max-width: 680px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 414px;
  height: 57px;
  top: 6px;
  position: relative;

  @media only screen and (max-width: 680px) {
    top: 31px;
    position: relative;
    flex-wrap: wrap;
    left: -5px;
    max-width: 212px;
  }

  .discord,
  .twitter,
  .instagram {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 680px) {
      order: 2;
    }
  }

  .copy {
    width: 175px;
    height: 40px;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 3px 3px 0px #000000;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;

    @media only screen and (max-width: 680px) {
      order: 1;
      margin: 0 0 10px;
    }

    img {
      margin-right: 10px;
    }

    &:hover {
      bottom: -3px;
      right: -3px;
      box-shadow: none;
      position: relative;
    }
  }
}

.backtohome {
  margin: 0 auto 50px;
  .btn {
    width: 207px;
    margin: 0 auto;
    height: 52px;
    padding: 0;
    &:before {
      display: none;
    }
  }

  a {
    width: 135px;
    &:before {
      display: none;
    }
  }
}

.pick-a-realm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 49px 30px 0;

  @media only screen and (max-width: 680px) {
    order: 1;
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }

  .moca-token {
    background-image: url(../../images/moca-token.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 26px;

    span {
      margin-left: 40px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #ffffff;
    }
  }
}
