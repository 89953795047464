.table-container {
  width: 100%;
  background: #EFEFEF;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 0 50px;
    span {
      color: #858585;
      font-weight: 700;
      font-size: 16px;
    }
    &--col-1 {
      width: calc(16% - 50px);
    }
    &--col-2, &--col-3, &--col-4, &--col-5 {
      width: 16%;
    }
    &--col-6 {
      justify-content: flex-end;
      flex-grow: 1;
    }
    &--col-1, &--col-2, &--col-3, &--col-4, &--col-5, &--col-6 {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    &--col-1::after, &--col-2::after, &--col-3::after, &--col-4::after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background-image: url(/assets/images/arrow-sort.svg);
      background-position-y: -1px;
    }
    &--col-3::before, &--col-6::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-image: url(/assets/images/info.svg);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 8px 50px;
    background: #FBFBFB;
    border: 1px solid #000000;
    border-radius: 12px;
    margin-bottom: 10px;

    &--col-1 {
      width: calc(16% - 50px);
    }
    &--col-2, &--col-3, &--col-4, &--col-5 {
      width: 16%;
    }
    &--col-6 {
      justify-content: flex-end;
      flex-grow: 1;
    }
    &--col-1 {
      font-weight: 800;
      font-size: 24px;
      color: #333333;
    }
    &--col-2 {
      display: flex;
      align-items: center;
      gap: 8px;
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid #545454;
      }
    }
    &--col-3 {
      font-weight: 700;
      font-size: 16px;
      color: #000000;
    }
    &--col-4 {
      font-weight: 700;
      font-size: 16px;
      color: #000000;
    }
    &--col-5 {
      font-weight: 500;
      font-size: 16px;
      text-decoration-line: underline;
      color: #333333;
    }
    &--col-6 {
      width: 200px;
      text-align: right;
      font-weight: 900;
      font-size: 32px;
      color: #fff;
      text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
             1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    gap: 16px;

    &--label {
      color: #5E5E5E;
      font-weight: 600;
      font-size: 20px;
    }
  }
}

